import React from "react";
import Layout from "../../components/layout";
import HeaderOne from "../../components/header/header-one";
import StickyHeader from "../../components/header/sticky-header";
import Footer from "../../components/footer";
import VMission from "../../components/about/vision-mission"
const VisionMission = () => {
  return (
    <Layout pageTitle="Vision & Mission">
      <HeaderOne />
      <StickyHeader />
      <VMission/>
      <Footer />
    </Layout>
  );
};
export default VisionMission