import React from "react";
import i18n from "i18n-js";
import { Container, Row, Col, Card } from "react-bootstrap";
import mission from "../../../static/assets/images/about/mission.svg";
const VMission = () => {
  return (
    <section className="mhh-bg-image approach-asha" >
      <Container>
        <Row noGutters className="justify-content-center">
          <Col xs={12} sm={12} md={4} lg={4} xl={4} id='about-mission-sec' >
            <div className="text-center">
              <img src={mission} className="about-mission-img" />
            </div>
          </Col>
          <Col xs={12} sm={12} md={8} lg={8} xl={8}>
            <div>
              <h1>{i18n.t("vision-and-mission-heading")}</h1>
              <div>
                <p dangerouslySetInnerHTML={{ __html:i18n.t("vision-mission-text1")}}></p>
                <p>{i18n.t("vision-mission-text2")}</p>
                <p>{i18n.t("vision-mission-text3")}</p>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  );
};
export default VMission;
